import { TrustpilotCarousel } from 'components/TrustpilotCarousel'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import SectionContainer from 'components/Section/SectionContainer'
import { TitleDivider } from 'components/TitleDivider'
import { useEffect, useRef } from 'react'

const TrustpilotCarouselSection = ({ content }) => {
  const ref = useRef(null)
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  const { title, config } = useMemoizedContentGetter(content, ['title', 'config'])
  const trustpilotCarouselConfig = config?.jsonContent || {}
  return (
    <Section data-testid="trustpilot-carousel-section" contain={false} defaultPadY>
      <SectionContainer>{title && <TitleDivider title={title} />}</SectionContainer>
      <TrustpilotCarousel reference={ref} {...trustpilotCarouselConfig} />
    </Section>
  )
}

export { TrustpilotCarouselSection }
export default TrustpilotCarouselSection
